<template>
  <v-footer
    id="dashboard-core-footer"
  >
    <v-container>
      <v-row
        align="center"
      >

        <v-spacer class="hidden-sm-and-down" />
        
        <v-col
          cols="12"
          md="auto"
          class="d-flex"
        >
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; 2020, made by
            <a href="https://google.com.br/">Norven</a>
          </div>
          <v-btn
          href="https://www.linkedin.com" 
          target="_blank"
          color="#0976B4"
          dark
          large
          icon
          class="body-1 font-weight-light pt-6 pt-md-0 text-center"
          min-width="0"
        >
          <v-icon
            :color="'#0976B4'"
            v-text="'mdi-linkedin'"
          />
        </v-btn>

        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({}),
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
